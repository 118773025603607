<template>
    <v-dialog persistent :value="show" max-width="450px">
        <v-card>
            <v-card-title class="justify-center align-center nuvolos-header">
                <v-img max-width="200" max-height="22" class="py-5" src="@/assets/Nuvolos-transparent.svg" />
            </v-card-title>
            <v-card-title class="justify-center text-h6">
                {{ message }}
            </v-card-title>
            <v-card-text>
                This might take a few minutes, please remain on this page.
                <v-progress-linear indeterminate color="primary" class="my-4"></v-progress-linear>
                <div>
                    In the meantime you can take a look at our
                    <a href="https://docs.nuvolos.cloud/" target="_blank" class="link">getting started guide</a>
                    .
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        message: String,
        show: Boolean
    },
    methods: {
        warnUnload(event) {
            event.preventDefault()
            // Older browsers supported custom message
            event.returnValue = ''
        }
    },
    watch: {
        show: {
            handler: function (to, from) {
                if (to) {
                    window.addEventListener('beforeunload', this.warnUnload)
                } else {
                    window.removeEventListener('beforeunload', this.warnUnload)
                }
            },
            immediate: true
        }
    }
}
</script>
<style lang="scss" scoped>
.nuvolos-header {
    background: rgba(241, 241, 241, 0.8);
    width: 100%;
    height: 150px;
}
.link {
    text-decoration: none;
    border-bottom: 1px dashed var(--v-anchor-base);
}
</style>
